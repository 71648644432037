<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6">
        <LastGames :loading="loadingLastGames" :lastGames="lastGames" :hideRematch="true"/>
      </div>
      <div class="col-md-6">
        <Rematch :loading="loadingLastGames" :lastGames="lastGames"/>
      </div>
    </div>
  </div>
</template>

<script>
import LastGames from "./LastGames";
import Rematch from "./Rematch";
import TMService from "../services/tm.service";

export default {
  name: "MyGames",
  components: { LastGames, Rematch },
  data() {
    return { 
      loadingLastGames: false,
      page: 1,
      size: 30,
      lastGames: [],
    };
  },
  mounted() { 
    this.getMyGames();
  },
  methods: {
    getMyGames(){
      TMService.getLastFinishedGames(null, this.page, this.size).then(
        (response) => {
          this.lastGames = response.data;
          this.loadingLastGames = false;
        },(error) => {
          console.log(error);
        });
    },
  }
};
</script>