<template>
  <div>
    <h3>Series</h3>
    <select id="inputMap" class="form-select" v-model="serie" @change="loadSeries">
      <option :value="0">Select</option>
      <option v-for="opt in options" :key="opt.id" :value="opt.id">
        {{ opt.name }}
      </option>
    </select>
    <table
      class="table table-sm table-stripped table-hover"
      style=""
      v-if="(data?.games?.length || 0) > 0"
    >
      <thead>
        <tr>
          <th>#</th>
          <th>url</th>
          <th v-for="pcount in numPlayers" :key="pcount">#{{ pcount + 1}}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(g, index) in data.games" :key="g.id">
          <td>{{ index + 1 }}</td>
          <td><a :href="g.url" target="_blank">{{ g.url }}</a></td>
          <td v-for="p in g.players" :key="p.username">{{ p.username }} ({{p.points}})</td>
        </tr>
      </tbody>
    </table>


    <table
      class="table table-sm table-stripped table-hover"
      style=""
      v-if="(data?.games?.length || 0) > 0"
    >
      <thead>
        <tr>
          <th>#</th>
          <th>Jogador</th>
          <th v-for="pcount in numPlayers" :key="pcount">#{{ pcount + 1 }}</th>
          <th>Pontos</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(j, index) in sortedPlayers" :key="j.username">
          <td>{{ index + 1  }}</td>
          <td>{{ j.username }}</td>
          <td v-for="p in numPlayers" :key="p">{{ j.position[p] || 0 }}</td>
          <td>{{ j.points }}</td>
        </tr>
      </tbody>
    </table>
    <table
      class="table table-sm table-stripped table-hover"
      style=""
      v-if="(data?.games?.length || 0) > 0"
    ></table>
  </div>
</template>

<script>
import TMService from "../services/tm.service";

export default {
  name: "Rematch",
  props: ["loading", "lastGames"],
  data() {
    return {
      serie: 0,
      options: [],
      data: {},
    };
  },
  computed: {
    numPlayers() {
      let arr = [0,1,2,3,4,5];
      return arr.slice(0, this.data?.games?.at(0).players.length);
    },
    sortedPlayers(){
      return [...Object.values(this.data.players)].sort((a,b) => { 
        return b.position.join('') - a.position.join('');
      });
    }
  },
  methods: {
    async getSeries() {
      let resp = await TMService.getSeries();
      this.options = resp?.data;
      if (this.options?.length == 0) {
        this.options.push({ id: -1, name: "Nao tens qualquer serie!" });
      }
    },
    async loadSeries() {
      if (this.serie > 0){ 
        let response = await TMService.getSeriesGames(this.serie);
        this.data = response.data;
      }
    },
  },
  async mounted() {
    await this.getSeries();
  },
};
</script>

<style scoped>
.table {
  font-size: 0.65em;
}
</style>